const domains = ['sweatco.in', 'swcapp.com', 'sweatcoin.digital', 'localhost:3000'];
const getDomainVariant = (origin, domain) => {
    for (let i = 1; i <= 6; i++) {
        if (origin.includes(`w${i}.${domain}`)) {
            return i;
        }
    }
    return 0;
};
const getVariant = (origin, isEnabled) => {
    if (!isEnabled) {
        return [0, 'var_0'];
    }
    for (const domain of domains) {
        const variant = getDomainVariant(origin, domain);
        if (variant !== 0) {
            return [variant, `var_${variant}`];
        }
    }
    return [0, 'var_0'];
};
export default getVariant;
